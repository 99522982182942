.App {
  display: flex;
  min-height: 100vh;
  padding-left: 180px;
  &Content {
    width: 100%;
    position: relative;
  }
}

@media screen and (max-width: 800px) {
  .App {
    padding: 0;
  }
}