.skills {
  min-height: 100vh;
  padding: 35px 30px;
  &Title {
    font-size: 50px;
    line-height: 1.1;
    font-weight: 700;
    margin-bottom: 26px;
    color: var(--font-color);
  }
  &Subtitle {
    font-size: 22px;
    line-height: 1.1;
    margin-bottom: 15px;
  }
  &Grid {
    display: grid;
    grid-template-columns: repeat(3, 270px);
    gap: 50px;
    justify-content: center;
    &Column {

    }
  }
  &Items {
    display: inline-flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-bottom: 30px;
    padding: 15px 20px;
    border-radius: 20px;
    background-color: var(--card-bg);
    box-shadow: 0 2px 15px 1px var(--card-shadow);
  }
  &Item {
    width: 100px;
    height: 100px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
}

.companies {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 50px;
}

.company {
  position: relative;
  color: #ffffff;
  padding: 20px 25px 35px;
  background-color: var(--secondary-color);
  &::after {
    content: "";
    display: block;
    height: 2px;
    width: 30px;
    background-color: var(--font-color);
    position: absolute;
    top: calc(50% - 1px);
    right: -40px;
  }
  &:last-child {
    &::after {
      display: none;
    }
  }
  &Title {
    font-size: 20px;
    line-height: 1.1;
    margin-bottom: 4px;
  }
  &Location {
    font-size: 14px;
    line-height: 1.1;
    margin-bottom: 10px;
  }
  &Date {
    font-size: 16px;
    line-height: 1.1;
    margin-bottom: 20px;
  }
  &List {
    padding-left: 16px;
    li {
      font-size: 13px;
      line-height: 1.5;
      margin-bottom: 8px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

@media screen and (min-width: 800px) and (max-width: 1140px) {
  .skills {
    &Title {
      font-size: 35px;
    }
    &Subtitle {
      font-size: 18px;
    }
    &Grid {
      gap: 15px;
      grid-template-columns: repeat(3, 200px);
    }
    &Item {
      width: 70px;
      height: 70px;
    }
  }

  .companies {
    gap: 30px;
  }

  .company {
    padding: 20px 15px 25px;
    &::after {
      width: 15px;
      right: -22.5px;
    }
  }
}

@media screen and (min-width: 800px) and (max-width: 970px) {
  .skills {
    &Grid {
      grid-template-columns: repeat(3, 170px);
    }
    &Item {
      width: 55px;
      height: 55px;
    }
  }

  .companies {
    grid-template-columns: 1fr;
  }

  .company {
    &::after {
      display: none;
    }
  }
}

@media screen and (max-width: 800px) {
  .skills {
    &Title {
      font-size: 30px;
    }
    &Grid {
      grid-template-columns: 1fr;
      gap: 25px;
      margin-bottom: 40px;
    }
    &Items {
      margin-bottom: 0;
    }
  }

  .companies {
    grid-template-columns: 1fr;
  }
  .company {
    &::after {
      top: auto;
      bottom: -42.5px;
      right: calc(50% - 1px);
      width: 2px;
      height: 35px;
    }
  }
}