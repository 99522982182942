.popup {
    position: relative;
    z-index: 110;
    max-width: 700px;
    width: 100%;
    padding: 20px;
    background-color: var(--card-bg);
    margin: 0 auto;
    border-radius: 10px;
    transform: translateY(0%);
    transition: transform 0.4s ease-in-out;
    img {
        max-width: 100%;
    }
    &Header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 30px;
    }
    &Heading {
        color: var(--font-color);
        font-size: 24px;
        font-weight: 500;
    }
    &Close {
        position: relative;
        padding: 0;
        border: 0;
        border-radius: 6px;
        width: 40px;
        height: 40px;
        background-color: transparent;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: background-color 0.3s ease-in-out;
        &::after,
        &::before {
            content: "";
            display: block;
            width: 30px;
            height: 2px;
            background-color: var(--font-color);
            transform: rotate(45deg);
            position: absolute;
        }
        &::before {
            transform: rotate(-45deg);
        }
        &:focus {
            outline: none;
        }
        &:hover {
            background-color: rgba(115, 115, 115, 0.05);
        }
    }
}

.overflow {
    position: fixed;
    z-index: 105;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    padding: 100px 0;
    transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out;
    &.hide {
        transform: 0;
        opacity: 0;
        visibility: hidden;
        .popup {
            transform: translateY(50%);
        }
    }
    &::-webkit-scrollbar {
        display: none;
    }
}

.overlay {
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    z-index: 105;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    cursor: pointer;
    transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
    &.hide {
        opacity: 0;
        visibility: hidden;
    }
}