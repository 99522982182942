.sidebar {
  height: 100%;
  width: 180px;
  z-index: 100;
  position: fixed;
  left: 0;
  top: 0;
  background-color: var(--sidebar-bg);
  display: flex;
  flex-direction: column;
  &Logo {
    width: 100%;
    height: 190px;
    background-color: var(--secondary-color);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &Img {
      max-width: 100px;
    }
    &Name {
      font-size: 16px;
      font-weight: 700;
      margin: 6px 0;
      color: var(--white-color);
    }
    &Label {
      font-weight: 400;
      font-size: 14px;
      color: var(--white-color);
    }
  }

  &Nav {
    display: flex;
    flex-direction: column;
    margin: auto 0;
    &Link {
      font-size: 18px;
      font-weight: 500;
      padding: 9px 10px;
      text-align: center;
      text-decoration: none;
      color: var(--font-color);
      transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;
      &:hover {
        background-color: rgba(135, 135, 135, 0.1);
        color: var(--secondary-color);
        [dark] & {
          color: var(--tertiary-color);
        }
      }
      &.active {
        background-color: rgba(135, 135, 135, 0.1);
        color: var(--secondary-color);
        [dark] & {
          color: var(--tertiary-color);
        }
      }
    }
  }

  &Social {
    padding: 0;
    margin: 0;
    list-style: none;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    gap: 8px;
    &Link {
      display: inline-block;
      svg {
        fill: var(--font-color);
        transition: fill 0.2s ease-in-out;
      }
      &:hover {
        svg {
          fill: var(--secondary-color);
          [dark] & {
            fill: var(--tertiary-color);
          }
        }
      }
    }
  }
}

.lightSwitch {
  border: 0;
  width: 40px;
  height: 40px;
  padding: 7px;
  margin-bottom: auto;
  align-self: center;
  cursor: pointer;
  border-radius: 50%;
  background-color: transparent;
  transition: background-color 0.2s ease-in-out;
  svg {
    fill: var(--font-color);
    path {
      fill-rule: evenodd;
    }
  }
  &:hover {
      background-color: rgba(157, 157, 157, 0.116);
  }
  &:focus {
    outline: none;
  }
}

.menuBtn {
  display: none;
}

@media screen and (max-width: 800px) {
  .sidebar {
    width: 100%;
    transform: translate(100%);
    transition: transform 0.5s ease-in;
    &.active {
      transform: translate(0);
    }
  }

  .menuBtn {
    background-color: transparent;
    position: relative;
    border: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    z-index: 1000;
    top: 15px;
    right: 15px;
    width: 30px;
    height: 30px;
    cursor: pointer;
    &Bar,
    &::before,
    &::after {
      content: "";
      display: inline-block;
      width: 30px;
      height: 2px;
      background-color: var(--font-color);
      position: absolute;
      z-index: 1;
      left: 0;
    }
    &Bar {
      top: 14px;
      transition: top 0.3s ease-in-out, transform 0.3s ease-in-out;
    }
    &::after {
      top: 4px;
      transition: top 0.3s ease-in-out, transform 0.3s ease-in-out;
    }
    &::before {
      bottom: 4px;
      transition: bottom 0.3s ease-in-out, transform 0.3s ease-in-out;
    }
    &:focus {
      outline: none;
    }
    &.active {
      .menuBtnBar {
        transform: rotate(45deg);
      }
      &::after {
        transform: rotate(45deg);
        top: 14px;
      }
      &::before {
        transform: rotate(-45deg);
        bottom: 14px;
      }
    }
  }
}