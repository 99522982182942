.about {
  min-height: 100vh;
  padding: 35px 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  &Title {
    font-size: 50px;
    line-height: 1.1;
    font-weight: 700;
    margin-bottom: 26px;
    color: var(--font-color);
  }
  &Desc {
    font-size: 16px;
    line-height: 1.5;
    color: var(--font-color);
    opacity: 0.9;
    margin-bottom: 16px;
  }
  &Block {
    max-width: 50%;
  }
  &Link {
    font-size: 18px;
    line-height: 1.1;
    color: var(--secondary-color);
    text-decoration: none;
    border-bottom: 1px solid var(--secondary-color);
    &:hover {
      border-color: transparent;
    }
  }
}

@media screen and (min-width: 800px) and (max-width: 970px) {
  .about {
    &Title {
      font-size: 35px;
    }
    &Block {
      max-width: 100%;
    }
  }
}

@media screen and (max-width: 800px) {
  .about {
      &Title {
        font-size: 30px;
      }
  
      &Block {
        max-width: 100%;
      }
    }
}