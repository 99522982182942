.home {
    width: 100%;
    min-height: 100vh;
    padding: 20px 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    &Title {
        color: var(--font-color);
        font-size: 40px;
        line-height: 1.2;
        font-weight: 700;
        letter-spacing: -0.02em;
        margin-bottom: 20px;
    }
    &Subtitle {
        color: var(--font-color);
        font-size: 24px;
        line-height: 1.2;
        font-weight: 400;
        margin-bottom: 50px;
        opacity: 0.9;
    }
    &Btn {
        border: 0;
        padding: 10px 35px;
        background-color: var(--secondary-color);
        color: var(--white-color);
        font-size: 18px;
        border-radius: 5px;
        cursor: pointer;
        text-decoration: none;
        transition: background-color 0.3s ease-in-out;
        &:hover {
            background-color: var(--tertiary-color);
        }
    }
    &Avatar {
        display: inline-flex;
        align-items: flex-end;
        border-radius: 50%;
        width: 350px;
        height: 350px;
        overflow: hidden;
        margin: 0 auto 30px;
        background-color: var(--secondary-color);
        transition: background-color 0.3s ease-in-out;
    }
    &Img {
        max-width: 100%;
        transform: scale(1.05);
    }
}

@media screen and (max-width: 500px) {
    .home {
        &Title {
            font-size: 30px;
        }
        &Subtitle {
            font-size: 16px;
            margin-bottom: 20px;
        }
        &Avatar {
            width: 250px;
            height: 250px;
        }
    }
}