:root {
  --background-color: #f9f7f7; // rgb(249, 247, 247)

  --font-color: #000000; // rgb(0, 0, 0)

  --font-color-secondary: #bfbfbf; // rgb(191, 191, 191)

  --primary-color: #dbe2ef; // rgb(219, 226, 239)

  --secondary-color: #3f72af; // rgb(63, 114, 175)

  --tertiary-color: #112d4e; // rgb(17, 45, 78)

  --white-color: #ffffff; // rgb(255, 255, 255)

  --sidebar-bg: #f0f0f0;

  --card-bg: #ffffff;

  --card-shadow: rgba(0, 0, 0, 0.1);

  --progress-bar-color: rgba(0, 0, 0, 0.1);

  --input-color: #3f3f3f;
}

[theme-type=dark] {
  --background-color: #000000; // rgb(0, 0, 0)

  --font-color: #f9f7f7; // rgb(249, 247, 247)

  --font-color-secondary: #404040; // rgb(64, 64, 64)

  --primary-color: #52057b; // rgb(82, 5, 123)

  --secondary-color: #892cdc; // rgb(137, 44, 220)

  --tertiary-color: #bc6ff1; // rgb(188, 111, 241)

  --sidebar-bg: #333333;

  --card-bg: #333333;

  --card-shadow: rgba(255, 255, 255, 0.1);

  --progress-bar-color: rgba(255, 255, 255, 0.1);

  --input-color: #f4f4f4;
}
