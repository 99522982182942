.contact {
  min-height: 100vh;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &Title {
    font-size: 40px;
    line-height: 1.1;
    text-align: center;
    margin-bottom: 30px;
  }
  &Links {
    display: flex;
    gap: 30px;
  }
  &Link {
    background-color: var(--card-bg);
    border-radius: 15px;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px;
    width: 200px;
    height: 260px;
    box-shadow: var(--card-shadow);
    text-decoration: none;
    &Icon {
      svg {
        fill: var(--font-color);
      }
    }
    &Title {
      font-size: 20px;
      margin-top: 15px;
      color: var(--font-color);
    }
  }
}

@media screen and (min-width: 800px) and (max-width: 970px) {
  .contact {
    &Links {
      gap: 20px;
    }
    &Link {
      width: 170px;
      height: 240px;
      padding: 20px;
    }
  }
}

@media screen and (max-width: 800px) {
  .contact {
    &Title {
      font-size: 30px;
    }
    &Link {
      height: 150px;
      padding: 20px 15px;
      &Title {
        font-size: 16px;
        margin-top: 10px;
      }
    }
    &Links {
      flex-direction: column;
    }
  }
}