@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;700&display=swap");
@import-normalize;
@import "./styles/_variables";

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  font-family: "Inter", sans-serif;
  background-color: var(--background-color);
  color: var(--font-color);
}
