.workCard {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: var(--card-bg);
    padding: 20px 30px;
    width: 25%;
    position: relative;
    cursor: pointer;
    background-repeat: no-repeat;
    background-position: center center;
    background-color: #ffffff;
    transition: background-size 0.3s ease-in-out;
    background-size: 60%;
    &.dark {
        background-color: #1f1f1f;
    }
    &.white {
        background-color: #ffffff;
    }
    &.gray {
        background-color: #323232;
    }
    &.primary {
        background-color: #dbe2ef;
    }
    &Label {
        font-size: 12px;
        text-transform: uppercase;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        width: 100px;
        height: 100px;
        border-radius: 50%;
        color: #ffffff;
        background: var(--secondary-color);
        transform: scale(0);
        transition: transform 0.3s ease-in-out;
    }
    &::before {
        content: "";
        display: block;
        padding-top: 100%;
    }
    &::after {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        display: block;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.2);
        transition: background-color 0.1s ease-in-out;
    }
    &:hover {
        background-size: 70%;
        .workCardLabel {
            transform: scale(1);
        }
        &::after {
            background-color: transparent;
        }
    }
}

@media screen and (min-width: 800px) and (max-width: 1140px) {
    .workCard {
        width: 33.3332%;
    }
}

@media screen and (max-width: 800px) {
    .workCard {
        width: 50%;
    }
}